<template>
  <v-container style="max-width: 1300px" class="mx-auto">
    <v-row class="mx-1">
      <h4>Санал асуулгууд</h4>
      <v-spacer></v-spacer>
      <v-btn
        class="bg-gradient-primary"
        dark
        @click="_addNewAsuulga"
        v-if="
          userData.role == 'admin' ||
          userData.role == 'schoolmanager' ||
          userData.role == 'superadmin'
        "
        >Асуулга+
      </v-btn>
    </v-row>
    <v-btn
      elevation="0"
      class="text-capitalize my-5 ml-1"
      dark
      color="bg-gradient-danger"
      v-if="userData.role == 'teacher'"
      @click.stop="_seeTeacherResults()"
    >
      Миний үр дүн
    </v-btn>
    <v-row class="mb-10" v-if="items != null && items.length > 0">
      <v-card
        v-for="(itm, nindex) in items"
        v-bind:key="nindex"
        elevation="2"
        rounded="xl"
        class="mx-4 my-4 d-flex flex-column"
        width="400"
        @click="_answer(itm)"
        :ripple="false"
      >
        <h4 class="mt-10 mx-2">{{ itm.title }}</h4>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            elevation="0"
            style="font-size: 17px"
            @click.stop="_goDetail(itm)"
            v-if="
              userData.role == 'superadmin' ||
              userData.role == 'schooladmin' ||
              userData.role == 'schoolmanager' ||
              userData.role == 'schooldirector' ||
              (itm.createdBy != null &&
                itm.createdBy != undefined &&
                userData.ref.path == itm.createdBy.path)
            "
          >
            <small class="text-capitalize">Дэлгэрэнгүй</small>
          </v-btn>
        </v-card-actions>
        <!--  
        <v-card-text>
          {{ itm.userId }},
          <span style="color: red">{{ userData.customer.id }}</span>
        </v-card-text> -->
        <v-card-text
          class="d-flex justify-space-between mt-n2"
          v-if="itm.createdAt"
        >
          <!-- {{ itm.createdAt.toDate() | moment("YYYY/MM/DD, h:mm") }} -->
          <span class="primary--text">{{ itm.createdByName }}</span>
        </v-card-text>
        <v-card-text class="py-0 my-0" v-if="itm.description">
          <div>
            <!-- {{ getXSubStr(itm.description, 100) }} -->
            {{ itm.description }}
          </div>
        </v-card-text>
        <v-card-text class="py-0 my-0">
          <v-container class="text-center" v-if="itm.videoUrl != null">
            <iframe
              width="100%"
              height="160"
              :src="getXYoutubeIframe(itm.videoUrl)"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn
            color="red"
            v-if="userData.role == 'superadmin' || itm.userId == userData.id"
            text
            @click.stop="_deleteNews(itm)"
          >
            <v-icon left> mdi-delete </v-icon>
            Устгаx
          </v-btn>
          <v-btn
            color="red"
            v-else-if="
              userData.role == 'schooladmin' ||
              userData.role == 'schoolmanager' ||
              userData.role == 'sts' ||
              (itm.createdBy != null &&
                itm.createdBy != undefined &&
                userData.ref.path == itm.createdBy.path)
            "
            text
            @click.stop="_deleteNews(itm)"
          >
            <v-icon left> mdi-delete </v-icon>
          </v-btn>
          <v-btn
            small
            elevation="0"
            class="text-lowercase"
            dark
            color="green"
            v-if="
              userData.role == 'schooladmin' ||
              userData.role == 'schooldirector' ||
              userData.role == 'superadmin' ||
              (itm.createdBy != null &&
                itm.createdBy != undefined &&
                userData.ref.path == itm.createdBy.path)
            "
            @click.stop="_seeResults(itm)"
          >
            үр дүн
          </v-btn>
          <v-btn
            small
            elevation="0"
            class="text-lowercase"
            dark
            color="green"
            v-else-if="userData.role == 'schoolmanager'"
            @click.stop="_seeManagerResults(itm)"
          >
            үр дүн
          </v-btn>
          <v-btn
            small
            elevation="0"
            class="text-lowercase"
            dark
            color="green"
            v-if="
              userData.role == 'teacher' &&
              userData['myClassRef-' + userData.school.currentYear]
            "
            @click.stop="_seeClassResults(itm)"
          >
            Ангийн үр дүн
          </v-btn>

          <v-btn
            small
            elevation="0"
            class="text-lowercase"
            dark
            color="grey"
            v-if="
              userData.role == 'schooladmin' ||
              userData.role == 'schoolmanager' ||
              userData.role == 'schooldirector' ||
              (itm.createdBy != null &&
                itm.createdBy != undefined &&
                userData.ref.path == itm.createdBy.path)
            "
            @click.stop="_addTeachers(itm)"
          >
            Багш+
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="userData.role == 'student'"
            small
            elevation="0"
            class="text-lowercase ma-4"
            dark
            color="red"
            @click.stop="_answer(itm)"
          >
            санал өгөx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
    <v-dialog v-model="schoolModal" width="600">
      <v-card class="pa-3" rounded="xl">
        <v-card-title class="px-4">Асуулга нэмэх</v-card-title>
        <v-card-actions>
          <v-text-field autofocus
            v-model.trim="object.title"
            dense
            label="Гарчиг"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-textarea
            auto-grow
            v-model.trim="object.description"
            dense
            label="Тайлбар мэдээлэл"
          ></v-textarea>
        </v-card-actions>
        <v-card-actions>
          <v-text-field 
            v-model.trim="object.videoUrl"
            dense
            label="Видео линк"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions class="py-0">
          <v-checkbox
            v-model="object.published"
            label="Нээлттэй /Нээлттэй болгосоноор энэ мэдээллийг ажилтангууд харах
                      боломжтой./"
          ></v-checkbox>
        </v-card-actions>

        <v-card-actions class="py-0">
          <v-checkbox
            v-model="object.forAllTeacher"
            label="Багш нарт"
          ></v-checkbox>
          <v-checkbox
            v-model="object.forAllStudent"
            label="Сурагчдад"
          ></v-checkbox>
          <v-checkbox v-model="object.forParent" label="Эцэг эx"></v-checkbox>
        </v-card-actions>
        <v-card-actions class="d-flex flex-row">
          <v-spacer></v-spacer>

          <v-btn text @click="schoolModal = !schoolModal" dark>Цуцлах</v-btn>
          <v-btn class="bg-gradient-primary text-capitalize" @click="_saveNewAsuulga" dark
            >Хадгалах</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      v-if="loading == true"
      style="position: fixed; z-index: 10000000; top: 45%; left: 45%"
    >
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");

export default {
  data() {
    return {
      items: [],
      object: {
        title: null,
        description: null,
        published: false,
      },
      customerData: null,
      loading: false,
      school: null,
      myAsuulguud: null,
      schoolModal: false,
    };
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userData", "currentUser"]),
  },
  methods: {
    _answer(itm) {
      if (this.userData.role == "student")
        this.$router.push({
          name: "AsuulgaCXAnswer",
          params: { sorilId: itm.ref.path },
        });
    },
    _addTeachers(itm) {
      this.$router.push({
        name: "AsuulgaTeachers",
        params: { sorilId: itm.ref.path },
      });
    },
    _seeManagerResults(itm) {
      this.$router.push({
        name: "AsuulgaResultsManager",
        params: { sorilId: itm.ref.path },
      });
    },
    _seeClassResults(itm) {
      this.$router.push({
        name: "AsuulgaResultsClassTeacher",
        params: { sorilId: itm.ref.path },
      });
    },
    _seeTeacherResults() {
      this.$router.push({
        name: "AsuulgaResultsTeacher",
      });
    },
    _seeResults(itm) {
      console.log(itm.ref.path);

      this.$router.push({
        name: "AsuulgaResults",
        params: { sorilId: itm.ref.path },
      });
    },
    _isSubmitted() {
      console.log(this.myAsuulguud);
      if (this.items != null) {
        for (const asuulga of this.items) {
          if (this.myAsuulguud != null) {
            for (const myasuulga of this.myAsuulguud) {
              if (asuulga.id == myasuulga.id) return true;
            }
          }
        }
      }
      return false;
    },
    _makePublish(items) {
      fb.db.collection(this.asuulgaPath).doc(items.id).update({
        published: !items.published,
      });
    },
    _getData() {
      this.userData.school.ref.collection("asuulga").onSnapshot((docs) => {
        this.items = [];
        docs.forEach((doc) => {
          let asuulga = doc.data();
          asuulga.ref = doc.ref;
          asuulga.id = doc.id;

          this.items.push(asuulga);
        });
      });
    },
    _goDetail(item) {
      // if (item.viewCount) {
      //   fb.db
      //     .collection(this.asuulgaPath)
      //     .doc(item.id)
      //     .update({
      //       viewCount: item.viewCount + 1,
      //     });
      // } else {
      //   fb.db.collection(this.asuulgaPath).doc(item.id).update({
      //     viewCount: 1,
      //   });
      // }
      // console.log(item)
      this.$router.push({
        // name: "XADetail",
        name: "AsuulgaEditing",
        params: { asuulgaPath: item.ref.path },
      });
    },
    _saveNewAsuulga() {
      if (
        this.object != null &&
        this.object.title != null &&
        this.object.title != ""
      ) {
        if (
          this.object.videoUrl &&
          this.object.videoUrl.toLowerCase().indexOf("youtube") == -1
        ) {
          alert("Та заавал youtube бичлэг оруулна уу");
          return;
        }
        if (this.object.id != null && this.object.id != "") {
          fb.db
            .collection(this.asuulgaPath)
            .doc(this.object.id)
            .update({
              title: this.object.title,
              description: this.object.description,
              published: this.object.published,
              modifiedAt: new Date(),
              createdBy: this.userData.ref,
              // /createdByName: this.userData.customer,
              //type: fb.db.doc(this.typePath),
              videoUrl: this.object.videoUrl ? this.object.videoUrl : null,
              // 'when': this.object.when!=undefined?this.object.when:null,
              // 'where': this.object.where!=undefined?this.object.where:null,
              // 'to': this.object.to!=undefined?this.object.to:null,
            });
        } else {
          this.object.createdAt = new Date();
          this.object.userId = this.userData.id;
          this.object.user = this.userData.ref;
          this.object.createdBy = this.userData;
          this.object.createdByName =
            this.userData.name != null ? this.userData.name : "";

          this.userData.school.ref
            .collection("asuulga")
            .doc()
            .set(this.object)
            .then(() => {
              console.log("OK");
              this.schoolModal = !this.schoolModal;
            });

          // console.log(this.school)
        }
        this.object = {
          title: null,
          published: false,
          description: null,
          when: null,
          where: null,
          to: null,
        };
      } else {
        alert("Та гарчиг, тайлбараа оруулна уу");
      }
    },
    _editNews(items) {
      this.object = Object.assign({}, items);
      this.schoolModal = !this.schoolModal;
    },
    _addNewAsuulga() {
      this.object = {};
      this.schoolModal = !this.schoolModal;
    },
    _deleteNews(items) {
      var result = confirm("Та " + items.title + " устгах уу?");
      if (result) {
        fb.db.collection(this.asuulgaPath).doc(items.id).delete();
      }
    },
    _sendNotif(data) {
      if (this.asuulgaPath && data && data.id) {
        var result = confirm("Та " + data.title + " зарлал илгээх үү?");
        if (result) {
          fb.db.collection(this.asuulgaPath).doc(data.id).update({
            notified: !data.notified,
          });
        }
      }
    },
  },
  created() {
    this._getData();
  },

  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
