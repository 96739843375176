<template>
  <v-app>
      <Asuulga
        class="mx-auto"
      ></Asuulga>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Asuulga from "./XAsuulga.vue";
// import myfunction from 'global/myfunction.js';
// import VueResource from 'vue-resource';
// Vue.use(VueResource);
export default {
  data() {
    return {
      userObject: null, // customer
    };
  },
  components: {
    Asuulga,
  },
  computed: {
    ...mapState(["currentUser", "userData"]),
  },
  created() {
  },

  methods: {
    
  },
};
</script>
